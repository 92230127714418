import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseDraggableModal',{ref:_vm.modal_name,attrs:{"name":_vm.modal_name,"max-height":"80vh","width":"70vw","fullscreen":_vm.$vuetify.breakpoint.xsOnly}},[_c(VCard,{attrs:{"loading":_vm.loading,"disabled":_vm.loading}},[_c('BaseModal',[_c(VToolbar,{staticClass:"draggable_selector",attrs:{"slot":"header","dark":"","color":"primary","dense":"","flat":""},slot:"header"},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.closeModal(_vm.modal_name)}}},[_c(VIcon,[_vm._v("mdi-close")])],1),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.page.title))]),_c(VSpacer),_c(VBtn,{attrs:{"text":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteToken.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-content-trash-can")])],1)],1),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c(VDivider),_c(VCardText,[_c(VTextField,{staticClass:"mt-5",attrs:{"disabled":"","label":_vm.$store.getters.translate('name')},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$store.getters.translate('name'))),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("*")])])]},proxy:true}]),model:{value:(_vm.record.name),callback:function ($$v) {_vm.$set(_vm.record, "name", $$v)},expression:"record.name"}}),_c('base-documentation',{ref:"api_documentation",attrs:{"name":'api_documentation',"src":'https://doc.clickup.com/d/h/2cjb1-701/dc5f28ec87bbf23'},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.$refs.api_documentation.$refs.modal_documentation.open()}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-book")]),_vm._v(" "+_vm._s(_vm.$store.getters.translate("api_documentation"))+" ")],1)]},proxy:true}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }